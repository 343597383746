<template>
  <section class="conOfAnnualMeeting h-100 mb-5">
    <v-container>
      <v-row class="mt-3">
        <v-col cols="12">
          <Breadcrumb :BreadcrumbList="BreadcrumbList" />
          <HeaderPage :headerPage="{ part1: 'Our', part2: 'Partners' }" />
        </v-col>
      </v-row>
    </v-container>

    <section class="mt-3" v-if="listItems && listItems.length > 0">
      <v-container>
        <v-row>
          <v-col
            md="3"
            sm="6"
            cols="12"
            v-for="(item, index) in listItems"
            :key="index"
          >
            <div class="conOfUmage">
              <v-img :src="item.image" alt="image Partners"></v-img>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <div class="loaderContainer" v-else-if="isLoading">
      <v-progress-circular
        :size="120"
        :width="4"
        indeterminate
        color="primary"
      ></v-progress-circular>
    </div>
    <EmptyState v-else></EmptyState>
  </section>
</template>

<script>
import EmptyState from "@/modules/shared/components/emptystate";
import HeaderPage from "@/modules/shared/components/header-pages";
import Breadcrumb from "@/modules/shared/components/breadcrumbs-pages";
import { ApiService } from "@/modules/shared/services/api";
let apiServices = new ApiService();
export default {
  data: () => ({
    pagination: {
      current_page: 1,
    },
    BreadcrumbList: [
      {
        text: "About Us",
        disabled: true,
      },
      {
        text: "Partners",
        disabled: false,
      },
    ],
    listItems: null,
    isLoading: false,
  }),
  components: {
    EmptyState,
    HeaderPage,
    Breadcrumb,
  },
  methods: {
    goToPage(page) {
      this.pagination.current_page = page;
      this.getListData();
      window.scrollTo(0, 0);
    },
    getListData() {
      this.listItems = [];
      this.isLoading = true;

      apiServices.get("partners").then((res) => {
        if (res) {
          this.listItems = res.data;
          this.pagination = res.meta;
          this.isLoading = false;
        } else {
          this.isLoading = false;
        }
      });
    },
  },
  created() {
    this.getListData();
  },
};
</script>
<style lang="scss" scoped>
@import "./_partners.scss";
</style>
